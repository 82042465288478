import {
  dateFormate,
  dateTimeFormat,
  dateTimeSecondFormat,
  dateDayFormat,
} from 'constants/teacherRecruitment';
import {
  format,
  getTime,
  formatDistanceToNow,
  getMonth,
  getYear,
  getDate,
  intervalToDuration,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  set,
  parseISO,
  differenceInMinutes,
} from 'date-fns';
import zhTW from 'date-fns/locale/zh-TW';
import vi from 'date-fns/locale/vi';
import enUS from 'date-fns/locale/en-US';
import { t } from 'utils/i18n';
import i18n from 'i18next';

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fToDate(date: Date | string | number) {
  return format(new Date(date), dateFormate);
}

export function fCNDate(date: Date | string | number) {
  return format(new Date(date), t('dateFormat.fCNDate', 'MM月dd日 yyyy'));
}

export function fCNDateDay(date: Date | string | number) {
  return format(new Date(date), dateDayFormat, {
    locale: i18n.language === 'zh' ? zhTW : i18n.language === 'vi' ? vi : enUS,
  });
}

export function fCNDateExcludeYear(date: Date | string | number) {
  return format(new Date(date), t('dateFormat.fCNDateExcludeYear', 'MM月dd日'));
}

export function fCNDateExcludeDay(date: Date | string | number) {
  return format(new Date(date), t('dateFormat.fCNDateExcludeDay', 'MM月 yyyy'));
}

export function fToTime(date: Date | string | number) {
  return format(new Date(date), 'HH:mm');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fYear(date: Date | string | number) {
  return getYear(new Date(date));
}

export function fMonth(date: Date | string | number) {
  return getMonth(new Date(date));
}

export function fDay(date: Date | string | number) {
  return getDate(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTime24H(date: Date | string | number) {
  return format(new Date(date), dateTimeFormat);
}

export function fDateTime24HSeconds(date: Date | string | number) {
  return format(new Date(date), dateTimeSecondFormat);
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: i18n.language === 'zh' ? zhTW : i18n.language === 'vi' ? vi : enUS,
  });
}

export function fDuration(duration: number) {
  return intervalToDuration({ start: 0, end: duration * 1000 });
}

export function fCurrentYear(date: Date | number) {
  return [startOfYear(date), endOfYear(date)];
}

export function fCurrentMonth(date: Date | number) {
  return [startOfMonth(date), endOfMonth(date)];
}

export function fCurrentWeek(date: Date | number) {
  return [startOfWeek(date), endOfWeek(date)];
}

export function fCurrentDay(date: Date | number) {
  return [startOfDay(date), endOfDay(date)];
}
export function fClearSeconds(date: Date | number) {
  return set(date, { milliseconds: 0, seconds: 0 });
}

export function formatDateWithChineseUnits(date = new Date()) {
  return format(date, 'yyyy年M月d日', { locale: zhTW });
}

export const formatDateForROC = (inputDate: string): string => {
  const dateObject = new Date(inputDate);

  const year = dateObject.getFullYear() - 1911;
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();

  return `自 ${year} 年 ${month} 月 ${day} 日`;
};

export const formatTimeRange = (startTime: string, endTime: string): string => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const date = format(start, 'yyyy年M月d日', { locale: zhTW });
  const startTimeStr = format(start, 'HH:mm');
  const endTimeStr = format(end, 'HH:mm');

  return `${date} ${startTimeStr} - ${endTimeStr}`;
};

export const formatTimeRangeWithDuration = (
  startTime: string,
  endTime: string,
): string => {
  const start = parseISO(startTime);
  const end = parseISO(endTime);

  const startTimeStr = format(start, 'HH:mm', { locale: zhTW });
  const endTimeStr = format(end, 'HH:mm', { locale: zhTW });
  const durationMinutes = differenceInMinutes(end, start);

  return `${startTimeStr} - ${endTimeStr} (${durationMinutes}分鐘)`;
};
